//接口请求域名
var API_URL = "api/";
if(process.env.NODE_ENV == 'development'){
	API_URL = "api/api/";
}else{
	API_URL = "api/";
}
import $ from 'jquery';
// import * as Api from '../api/Data';
//序列化json对象
function serializationJson(params){
	let qs = require('qs');
	return qs.stringify(params);
}
//接口地址
export default{
		getemailcode:"api/" + "mss/emailCode",//邮箱验证码
		searchMem:API_URL + 'member/index/search',
    loginUrl: API_URL + 'uaa/oauth/token',
    ownerData:API_URL + 'member/login/index',
    safeCodeUrl: API_URL + 'mss/smsCode',
    refreshToken:  API_URL + 'uaa/oauth/refresh',
		getservetype: API_URL + 'smartwe/shop/serve/type',
		addserve: API_URL + 'smartwe/shop/serve/add',
		deleteserve: API_URL + 'smartwe/shop/serve/delete',
		getservelist: API_URL + 'smartwe/shop/serve/list',
		getshoplist: API_URL + 'smartwe/admin/shop/list',
		getprovince: API_URL + 'smartwe/area/province',
		getcity: API_URL + 'smartwe/area/city',
		gettown: API_URL + 'smartwe/area/town',
		imageUpload: API_URL + 'smartwe/image/shopLogoUpload',
		setshop: API_URL + 'smartwe/admin/shop/new',
		getshopdetail: API_URL + 'smartwe/admin/shop/detail',//店铺详情
		editshop:API_URL + 'smartwe/admin/shop/edit',//编辑店铺
		getmachinetype: API_URL + 'smartwe/shop/machine/type',
		getshopmachine: API_URL + 'smartwe/shop/machine/list',
		uploadmFileApi: API_URL + 'smartwe/image/shopMachineUpload',//点餐机图片管理
		setshopmachine: API_URL + 'smartwe/shop/machine/set',//店铺设置机器
		getshopservice: API_URL + 'smartwe/shop/serve/list',//服务列表
		addshopservice: API_URL + 'smartwe/shop/serve/add',//追加服务
		deleteshopservice: API_URL + 'smartwe/shop/serve/delete',//删除服务
		openOrClose: API_URL + 'smartwe/shop/openOrClose',//关店开店
		setmobileorder: API_URL + 'smartwe/admin/shop/mobile/order/new',//手机点餐
		getMobileOrder: API_URL + 'smartwe/admin/shop/mobile/order/query',//查询
		updateMobileOrder: API_URL + 'smartwe/admin/shop/mobile/order/update',//编辑
		addbrand: API_URL + 'smartwe/admin/brand/add',//新建品牌
		brandpermission: API_URL + 'smartwe/admin/brand/add/permission',//权限设置
		getbranddetail: API_URL + 'smartwe/admin/brand/detail',//品牌详情
		getbrandlist: API_URL + 'smartwe/admin/brand/list',//品牌列表
		removepermission: API_URL + 'smartwe/admin/brand/remove/permission',//移除权限
		savebrand: API_URL + 'smartwe/admin/brand/save',//保存品牌
		adddistrict: API_URL + 'smartwe/admin/district/add',//新增地区
		addDistrictPermission: API_URL + 'smartwe/admin/district/add/permission',//新增权限
		removeDistrictpermission: API_URL + 'smartwe/admin/district/remove/permission',//移除权限
		savedistrict: API_URL + 'smartwe/admin/district/save',//保存区域
		getDisdetail: API_URL + 'smartwe/admin/district/detail',//地区详情
		adddistrictshop: API_URL + 'smartwe/admin/shop/new',//保存区域
		editdistrictshop: API_URL + 'smartwe/admin/shop/update',//保存店铺
		addshoppermission: API_URL + 'smartwe/admin/shop/add/permission',//添加店铺权限
		romoveshoppermission: API_URL + 'smartwe/admin/shop/remove/permission',//删除店铺权限
		enableshop: API_URL + 'smartwe/admin/shop/enable',//开启店铺
		disableshop: API_URL + 'smartwe/admin/shop/disable',//关闭店铺
		getmachinelist: API_URL + 'smartwe/admin/machine/list',//设备列表
		newMachine: API_URL + 'smartwe/admin/machine/new',//新建设备
		machineDetail: API_URL + 'smartwe/admin/machine/detail',//设备详情
		disablemachine: API_URL + 'smartwe/admin/machine/disable',//禁用机器
		enablemachine: API_URL + 'smartwe/admin/machine/enable',//启用机器
		reimbursedisable: API_URL + 'smartwe/admin/machine/reimburse/disable',//返金不可
		reimburseenable: API_URL + 'smartwe/admin/machine/reimburse/enable',//返金可
		setmachinepay: API_URL + 'smartwe/admin/machine/pay/set',//设置支付方式
}
