import Vue from 'vue'
import App from './App.vue'
import router from './router'
import './registerServiceWorker'
import './plugins/element.js'
import serverJs from './utils/server.js'
import req from './utils/ahttp.js'
import 'font-awesome/css/font-awesome.css';
// import "./style/tailwindcss.css"
import Api from './api/Api.js'
import axios from 'axios'
Vue.prototype.$ax= axios
Vue.use(Api);
Vue.prototype._Api = Api;
Vue.use(serverJs);
Vue.prototype.SV = serverJs;
Vue.prototype.req = req;
Vue.config.productionTip = false

router.beforeEach((to, from, next) => {
	  if (to.path == '/login') {
			if(getCookie("access_token")){
				next({
					path: '/index'
				})
			}else{
				next();
			}
	  }else{
			if(getCookie("access_token")){
				next();
			}else{
				next({
					path: '/login'
				})
			}
	  }
	  if (to.meta.title) {
	    document.title = to.meta.title
	  }else{
	    document.title = "SmartWe管理システム"
	  }
});
new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
console.log = function(){

};
function setCookie(c_name,value,day,domain){
		var exdate=new Date();
		exdate.setTime(exdate.getTime()+day*24*60*60*1000);
		document.cookie=c_name+ "=" +escape(value)+((day==null) ? "" : ";expires="+exdate.toGMTString())+";path=/;domain="+domain;
}
function getCookie(cname){
	var name = cname + "=";
	var ca = document.cookie.split(';');
	for(var i=0; i<ca.length; i++)
	{
		var c = ca[i].trim();
		if (c.indexOf(name)==0) return c.substring(name.length,c.length);
	}
	return "";
}
