import axios from 'axios';
import router from '../router'
import CM from './common.js'
import {
  Message,
  Loading
} from 'element-ui';
let needLoadingRequestCount = 0,
loading;
const http = axios.create({
    timeout: 300000, //超时时长5分钟,
    crossDomain: true
});
var API_URL = "api/";
if(process.env.NODE_ENV == 'development'){
	API_URL = "api/api/";
}else{
	API_URL = "api/";
}
//添加请求拦截器
let tempConfig = '';
let nTime=Date.parse( new Date())/1000;
let callbacks = []
function onAccessTokenFetched(newToken) {
    callbacks.forEach(callback => {
        callback(newToken)
    })
    // 清空缓存接口
    callbacks = []
}

// 添加缓存接口
function addCallbacks(callback) {
    callbacks.push(callback)
}
http.interceptors.request.use(config => {
  if (needLoadingRequestCount === 0) {
    loading = Loading.service({
      lock: true,
      text: 'loading',
      spinner: 'el-icon-loading',
      background: 'rgba(0, 0, 0, 0.2)'
    })
  }
  needLoadingRequestCount++
	let iflogin = false;
	let tkcookie = CM.getCookie("access_token");
	var authKey;
	if(tkcookie){
		iflogin = true;
		authKey = CM.getCookie("token_type")+" "+tkcookie;	
	}else{
		iflogin = false;
		authKey = "";
	}
	if(iflogin){
		config.headers.Authorization = authKey;
	}else{
		config.headers.Authorization = '';
	}
	tempConfig = config;
	return config;
}, err => {
    // 请求发生错误时的相关处理 抛出错误
   Promise.reject(err)
})

let isRefreshToken = false; // 正在刷新token
http.interceptors.response.use(
    response => {
      if (needLoadingRequestCount <= 0) return
      needLoadingRequestCount--
      if (needLoadingRequestCount === 0) {
        loading.close()
      }
      console.log("response.data.code",response.data.code);
      if (response.data.code && response.data.code != 200) {
        Message({
         message: res.data.msg,
         type: "error",
         customClass: 'messageIndex'
        })
        return;
      }
			return response.data;
    },
    error => {
      if (needLoadingRequestCount <= 0) return
      needLoadingRequestCount--
      if (needLoadingRequestCount === 0) {
        loading.close()
      }
		if(error.response&&error.response.status){
			switch (error.response.status) {
				//401: 未登录-授权过期
				case 401:
				//如有刷新token的需求,可放开以下代码
				let config = error.response.config
				const retryOriginalRequest = new Promise(resolve => {
					 addCallbacks(newToken => {
							 // 表示用新的token去替换掉原来的token
							 config.headers.Authorization = newToken
							 resolve(http.request(config)) // 调用resolve请求队列里面接口
					 })
			 	});
				let iflogin = false;
				if(CM.getCookie("access_token")){
					iflogin = true;
				}else{
					iflogin = false;
				}
				if (!iflogin) {
					return;
				}
				if (!isRefreshToken) {
					const newhttp = axios.create({
					    timeout: 300000, //超时时长5分钟,
					    crossDomain: true
					});
					let iflogin = false;
					if(CM.getCookie("access_token")){
						iflogin = true;
					}else{
						iflogin = false;
					}
					newhttp.post(API_URL+'uaa/oauth/token', {
						"grant_type":"refresh_token",
						"client_id":"smartwe",
						"client_secret":"smartwe",
						"scope":"smartwe",
						"refresh_token":CM.getCookie("refreshToken")
						},{
					    headers: {
								"Content-Type":"application/x-www-form-urlencoded",
					      // "Authorization": "Basic d2ViQXBwOndlYkFwcA=="
					    }
					  }).then(resp => {  // 用refreshToken获取新的token
							isRefreshToken = true;
							let obj={
								access_token:resp.data.access_token,
								usename:resp.data.usename,
								token_type:resp.data.token_type,
								timestamp1:Date.parse( new Date())/1000,
								memberLevel:resp.data.memberLevel,
								refreshToken:resp.data.refresh_token
							}
							CM.setCookie("access_token",resp.data.access_token,2,".smartwe.co.jp");
							CM.setCookie("usename",resp.data.usename,2,".smartwe.co.jp");
							CM.setCookie("token_type",resp.data.token_type,2,".smartwe.co.jp");
							CM.setCookie("timestamp1",Date.parse( new Date())/1000,2,".smartwe.co.jp");
							CM.setCookie("refreshToken",resp.data.refresh_token,2,".smartwe.co.jp");
							localStorage.setItem('loginData', JSON.stringify(obj));
					onAccessTokenFetched(resp.data.token_type+" "+resp.data.access_token)
            }).catch(() => {
                // 刷新token错误跳转到登陆页面
				document.cookie = "access_token=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;domain=.smartwe.co.jp";
				document.cookie = "access_token=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
				localStorage.removeItem('loginData');
								router.push('/login');
            }).finally(() => {
                isRefreshToken = false
            })
        }
				return retryOriginalRequest;
				break;
				case 500:
          Message({
           message: error.message,
           type: "error",
           customClass: 'messageIndex'
          })
				break;
				case 503:
          Message({
           message: error.message,
           type: "error",
           customClass: 'messageIndex'
          })
				break;
			}
			return Promise.reject(error.response);
		}
		  if (axios.isCancel(error)) {
      		//取消请求
    	  } else {

    		}
        return Promise.reject(error.response);
    }
);

export default http;
