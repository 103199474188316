import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
		path: '/',
		redirect: '/login'
	},
  {
		path: '/login',
		component: () => import('../views/login.vue'),
    meta: {
			title: 'SmartWeログイン'
		}
	},
	{
		path: '/home',
		component: () => import('../views/home.vue'),
		children:[
      {
    		path: '/index',
    		component: () => import('../views/brand/brandlist.vue'),
        meta: {
    			title: 'SmartWeブランド管理'
    		}
    	},
      {
    		path: '/service',
    		component: () => import('../views/service/service.vue'),
        meta: {
    			title: 'サービス管理'
    		}
    	},
      {
    		path: '/brandlist',
        name:"brandlist",
    		component: () => import('../views/brand/brandlist.vue'),
        meta: {
    			title: 'ブランドリスト'
    		}
    	},
		{
    		path: '/shoplist',
        	name:"shoplist",
    		component: () => import('../views/shopcharge/shoplist.vue'),
        	meta: {
    			title: '店舗リスト'
    		}
    	},
      {
    		path: '/branddetail',
        name:"branddetail",
    		component: () => import('../views/brand/branddetail.vue'),
        meta: {
    			title: 'ブランド管理'
    		}
    	}
    ]
  },
]

const router = new VueRouter({
  mode: 'history',
  routes
})

export default router
