
export default{
  //获取表格高度
  getTableHeight(){
      return document.body.clientHeight - 315;
  },
  //从sessionStorage获取pageSize
  getPageSize(){
      return sessionStorage.pageSize == null ? 25 : +sessionStorage.pageSize;
  },
  //表头显示列的数组
  getHeaderShowColumns(apiData){
    let result = [];
    apiData.forEach((item,i) => {
      if(apiData[i].isShow === 1){
        result.push(apiData[i].englishName);
      }
    });
    return result;
  },
  setCookie(c_name,value,day,domain){
      var exdate=new Date();
      exdate.setTime(exdate.getTime()+day*24*60*60*1000);
      document.cookie=c_name+ "=" +escape(value)+((day==null) ? "" : ";expires="+exdate.toGMTString())+";path=/;domain="+domain;
  },
  getCookie(cname){
    var name = cname + "=";
    var ca = document.cookie.split(';');
    for(var i=0; i<ca.length; i++)
    {
      var c = ca[i].trim();
      if (c.indexOf(name)==0) return c.substring(name.length,c.length);
    }
    return "";
  },
  //表格需要显示的列 apiData:接口数据 columns:自定义列数据
  getShowHeader(apiData,columns){
    let result = [];
    apiData.forEach((item,i) => {
      let key = apiData[i].englishName;
          if(apiData[i].isShow === 1 && typeof columns[key] !== "undefined"){
            result.push(columns[key]);
          }
    });
    return result;
  },
  compress(fileObj, callback) {
  try {
    const image = new Image()
    image.src = URL.createObjectURL(fileObj)
    image.onload = function() {
      const that = this
      // 默认按比例压缩
      let w = that.width
      let h = that.height
      const scale = w / h
      w = fileObj.width || w
      h = fileObj.height || (w / scale)
      let quality = 0.7 // 默认图片质量为0.7
      // 生成canvas
      const canvas = document.createElement('canvas')
      const ctx = canvas.getContext('2d')
      // 创建属性节点
      const anw = document.createAttribute('width')
      anw.nodeValue = w
      const anh = document.createAttribute('height')
      anh.nodeValue = h
      canvas.setAttributeNode(anw)
      canvas.setAttributeNode(anh)
      ctx.drawImage(that, 0, 0, w, h)
      // 图像质量
      if (fileObj.quality && fileObj.quality <= 1 && fileObj.quality > 0) {
        quality = fileObj.quality
      }
      // quality值越小，所绘制出的图像越模糊
      const data = canvas.toDataURL('image/jpeg', quality)
    const bytes = window.atob(data.split(',')[1]) // 去掉url的头，并转换为byte
      // 处理异常,将ascii码小于0的转换为大于0
      const ab = new ArrayBuffer(bytes.length)
      const ia = new Uint8Array(ab)
      for (let i = 0; i < bytes.length; i++) {
        ia[i] = bytes.charCodeAt(i)
      }
      // 压缩完成执行回调
      const newFile = new Blob([ab])
      callback(newFile)
    }
  } catch (e) {
    console.log('压缩失败!')
  }
},
}
